<template>
  <div class="terms">

        <div class="content-header row">
          <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
              <div class="col-12">

                <div class="breadcrumb-wrapper col-12">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Dashboard</a>
                    </li>
                    <li class="breadcrumb-item"><a href="#">Get Quote</a>
                    </li>
                    <li class="breadcrumb-item"><a href="#">Quote Summary</a>
                    </li>
                    <li class="breadcrumb-item"><a href="#">Payment</a>
                    </li>
                    <li class="breadcrumb-item active">Underwriting
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <hr/>
          </div>
        </div>
          <div class="row">
            <div class="col-md-7">
              <div class="quote-summary">
                  <h4>YOUR QUOTE SUMMARY</h4>

                <div class="row">
                  <div class="col-md-6 offset-6">
                    <ul class="list-group float-right">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Value of the car : &nbsp;&nbsp;
                        <span > KES. {{ quotation.sumInsured | currency(' ', 0) }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Product:
                        <span >SWITCH</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Cover Period:
                        <span >{{ quote_summary.cover_period }}</span>
                      </li>
                    </ul>
                  </div>
                </div>


                  <h5> {{ quotation.make | capitalize }} {{ quotation.model | capitalize }} {{ quotation.yearOfManufacture  }}</h5>
              </div>

              <div class="shadow-lg mt-3 p-4 mb-5 bg-white rounded">

                <div class="mb-4">
                  <h4>Comprehensive</h4>
                </div>

                <!--<div class="mb-2">
                  <h6>Additional Benefits</h6>
                </div>-->


                <div class="form-group mb-3" v-for="benefit in additional_benefits">
                  <label>
                    <input type="checkbox" name="comprehensive" class="check-mark"> &nbsp;&nbsp; {{ benefit }}
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-5">

              <div class="quote-success">
                <div class="row">
                  <div class="col-md-5" style="margin-bottom: 5px;">
                    <img style="max-height: 100px;" src="@/client/assets/images/congragulations.png" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h4 style="margin-bottom: 5px;">Congratulations</h4>
                    <p>You qualify for a discount of <br/><br/> <span class="secondary-color quotation-primary-color" style="font-size: 1.8em;">KES. {{(quotation.traditionalPremium - quotation.distancePremium) | currency('', 0)}} </span> </p>
                  </div>
                </div>

              </div>


              <div class="p-1 mb-1 ">
                <div class="mb-1" >
                  <h4  class="float-right">Quote Summary</h4>
                </div>


                  <table class="table table-bordered">
                    <tr v-for="item in quote_summary.items">
                        <th >{{ item.name }}</th>
                        <td>KES. {{ item.value | currency('', 0) }}</td>
                    </tr>
                    <tr >
                      <td style="border-bottom: 1px solid #000 !important;border-top: 1px solid #000 !important;font-weight: bolder;">Total </td>
                      <td style="border-bottom: 1px solid #000 !important;border-top: 1px solid #000 !important;" class="text-danger">KES. {{ quotation.distancePremium | currency('', 0) }}</td>
                    </tr>
                  </table>



              </div>
            </div>
          </div>
        <div class="row mb-1">
          <div class="col-md-12">
            <hr/>
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-md-3 offset-3">
            <button @click="goBackToHomepage" class="btn btn-lg quote-btn btn-block btn-outline-dark">  Back</button>
          </div>
          <div class="col-md-3">
            <button @click="navigateToCoverWizard" class=" btn  btn-lg  quote-btn quotation-button">  Buy Now </button>
          </div>
          <div class="col-md-3"></div>
        </div>

  </div>
</template>

<script>

  export default {
    name: 'home',
    data () {
      return {
        quotation:{},
        quote_summary:{
          cover_period:"One Year",

          items:[
            {
              name:"Training Levy",
              value:0
            },
            {
              name:"PCHF",
              value:0
            },
            {
              name:"Stamp Duty",
              value:0
            },
            {
              name:"Road Rescue",
              value:0
            },
            {
              name:"Promo Discount",
              value:0
            }
          ],
        },
        additional_benefits:[
                /*"Loss of Use",
                "Terrorism, sabotage & political risks",
                "Excess protector",
                "24 Hour Road Rescue Fees",
                "24 Hour Road Rescue (Infama)"*/
        ]
      };
    },
    computed:{
      total(){

        return this.quote_summary.items.reduce((sum, item) => sum + item.value, 0)
      }
    },
    mounted(){

      this.quotation = JSON.parse(localStorage.getItem("quote"));

      this.quote_summary.items.splice(0,0 ,{
        name:"Gross Premium",
        value: this.quotation.traditionalPremium
      })

      this.quote_summary.items.splice(this.quote_summary.items.length - 1,0 ,{
        name:"Personalized Discount",
        value: this.quotation.traditionalPremium - this.quotation.distancePremium
      })
    },

    methods:{
      goBackToHomepage(){
        this.$router.go(-1);
      },
      navigateToCoverWizard(){

        this.$router.push('/app/checkout')
      }
    }
  }
</script>

<style>

  .list-group-item {
    background-color: transparent !important;
    color:#fff !important;
    padding: 5px !important;
    border:none !important;
  }

  .quote-summary{
    padding: 20px;
    background-color: #2F3641;
    border-radius: 5px;
    color:#fff;
  }

  .quote-summary h4 {
    font-size: 1.2em;
  }

  .quote-summary h4, .quote-summary h5,  .quote-success h4{
    color:#fff;
  }

  .quote-success{
    background-color:#13233A;
    border-radius: 5px;
    padding: 40px 20px;
    color:#fff;
  }

  .quote-btn{
    width: 100%;
  }

  .table th, .table td{
    padding: 7px;
  }



</style>
